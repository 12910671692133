<template>
  <div>
    <v-row>
      <v-col cols="8" md="6">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn to="/transferencia/nova" class="text-capitalize" large color="primary"
          v-if="$acl.can('stockTransferCreateEdit')">
          Nova
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" cols="12">
        <CompanySelect @input="setCompanyOrigin($event)" label="Origem" />
      </v-col>
      <v-col md="3" cols="12">
        <CompanySelect @input="setCompanyDestination($event)" label="Destino" />
      </v-col>

      <v-col>
        <app-text-field v-model="searchParams.issued_at_start" type="date" label="Emissão Inicial" />
      </v-col>
      <v-col>
        <app-text-field v-model="searchParams.issued_at_end" type="date" label="Emissão Final" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <ProductSearch @input="setProduct($event)" clearable />
      </v-col>
      <v-col>
        <v-chip-group @change="select()" v-model="searchParams.status" label="Status" mandatory>
          <v-chip small filter value="draft">Rascunho</v-chip>
          <v-chip small filter value="confirmed">Confirmado</v-chip>
          <v-chip small filter value="received">Recebido</v-chip>
          <v-chip small filter value="canceled">Cancelado</v-chip>
          <v-chip small filter value="pending">Pendente</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>


      <v-col>
        <v-data-table :headers="headers" :items="transfers.data" :items-per-page='-1'
           hide-default-footer @click:row="handleViewTransfer($event.id)"
          disable-sort dense>
          <template v-slot:[`item.origin`]="{ item }">
            {{ item.company_origin.name }}
          </template>
          <template v-slot:[`item.destination`]="{ item }">{{
            item.company_destination.name
            }}</template>
          <template v-slot:[`item.issued_at`]="{ item }">{{
            $format.dateTimeBr(item.issued_at)
            }}</template>
          <template v-slot:[`item.status`]="{ item }">
            <DeliveryStatusChip small :status="item.status" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon color="primary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item v-if="
                  item.status == 'confirmed' &&
                  $acl.can('stockTransferReceive')
                " @click="handleTransferReceipt(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-truck-check-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Receber Mercadorias</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="
                  item.status == 'draft' &&
                  $acl.can('stockTransferCreateEdit')
                " @click="handleEditTransferReceipt(item.id)">
                  <v-list-item-icon>
                    <app-icon>edit</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="canPrint(item.status)" @click="printTransfer(item.id)">
                  <v-list-item-icon>
                    <app-icon>print</app-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Imprimir</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="transfers" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import DeliveryStatusChip from "@/components/delivery/section/DeliveryStatusChip.vue";
import ProductSearch from "@/components/product/ui/ProductSearch.vue";

export default {
  components: {
    CompanySelect,
    DeliveryStatusChip,
    ProductSearch
  },
  data() {
    return {
      headers: [
        { text: "Cod.", value: "code", align: "center" },
        { text: "Origem", value: "origin", align: "center" },
        { text: "Destino", value: "destination", align: "center" },
        { text: "Emitido", value: "issued_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: "1px" },

        { text: "", value: "action", width: "1px" },
      ],
      transfers: {},
      searchParams: {
        status: "pending",
      },
      showFilter: false,
    };
  },
  created() {
    if (this.$route.query.status) {
      this.searchParams.status = this.$route.query.status;
    }
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("stock/stock-transfer", this.searchParams)
        .then((response) => {
          this.transfers = response.transfers;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleEditTransferReceipt(id) {
      console.log(id);
      this.$router.push({
        name: "UpdateStockTransfer",
        params: { id: id },
      });
    },
    handleTransferReceipt(id) {
      this.$http
        .store("stock/stock-transfer/receipt", {
          id: id,
        })
        .then((response) => {
          this.select();
        });
    },

    handleCancelTransferReceipt(id) {
      this.$http
        .$post("stock/stock-transfer/receipt-cancel", {
          id: id,
        })
        .then((response) => {
          this.select();
        });
    },

    handleCancelTransfer(id) {
      this.$http
        .$post("stock/stock-transfer/cancel", {
          id: id,
        })
        .then((response) => {
          this.select();
        });
    },

    handleViewTransfer(id) {
      this.$router.push({
        name: "ViewStockTransfer",
        params: { id: id },
      });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    setCompanyOrigin(company) {
      this.searchParams.company_origin_id = company.id;
      this.select();
    },
    setCompanyDestination(company) {
      this.searchParams.company_destination_id = company.id;
      this.select();
    },
    printTransfer(id) {
      window.open(`/transferencia/imprimir/${id}`);
    },
    setProduct(product) {
      this.searchParams.product_id = product ? product.id : null;
      this.select();
    },
    canPrint(status) {
      console.log(status);
      console.log(status != "pending");
      return status != "pending" && status != "draft";
    },
  },
};
</script>

<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}

.expanded-table {
  background-color: #f5f5f5 !important;
}

.expanded-table td {
  border-bottom: red !important;
}

.updatedRow {
  color: green !important;
}
</style>